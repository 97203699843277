import dayjs from 'dayjs';

import { getRandomId } from './general';

const setTabSessionCreated = (tabId: string) => {
  if (localStorage) {
    const currentTime = new Date().toISOString();
    localStorage.setItem(`tabSession-${tabId}-createdAt`, currentTime);
  }
};

const getAllOldTabIds = () => {
  const oldTabIds: string[] = [];
  const localStorageEntries = Object.entries(localStorage);
  for (const entry of localStorageEntries) {
    const match = entry[0].match(/^tabSession-([\da-z]+)-createdAt$/);
    if (match && match[1]) {
      const tabId = match[1];
      const createdAt = entry[1];
      if (dayjs().isAfter(dayjs(createdAt).add(30, 'days'))) {
        oldTabIds.push(tabId);
      }
    }
  }
  return oldTabIds;
};

const cleanUpOldTabSessions = () => {
  if (!localStorage) {
    return;
  }
  const oldTabIds = getAllOldTabIds();
  for (const tabId of oldTabIds) {
    const localStorageEntries = Object.entries(localStorage);
    for (const entry of localStorageEntries) {
      if (new RegExp(`^${tabId}-`).test(entry[0])) {
        localStorage.removeItem(entry[0]);
      }
    }
    localStorage.removeItem(`tabSession-${tabId}-createdAt`);
  }
};

export const getTabScopedKey = (key: string) => {
  if (typeof window === 'undefined') {
    // SSR
    return key;
  }
  let tabId = sessionStorage.getItem('tabId');
  if (!tabId) {
    tabId = getRandomId();
    sessionStorage.setItem('tabId', tabId);
    setTabSessionCreated(tabId);
    cleanUpOldTabSessions();
  }
  return `${tabId}-${key}`;
};
